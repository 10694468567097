import React from "react"

const AboutUs = ({ head, content, image }) => {
  return (
    <>
      <section id="about" className="about">
        <div className="container">
          <div
            className="section-title"
            dangerouslySetInnerHTML={{ __html: head }}
          ></div>
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="about-img">
                <img src={image} alt="" />
              </div>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutUs
