import React from "react"
import Slider from "react-slick"
import _uniqueId from "lodash/uniqueId"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const PUBLISHED = "published"

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
}

const blockquoteRender = values => {
  const cards = []
  if (values.length) {
    for (const value of values) {
      if (value.status === PUBLISHED) {
        cards.push(
          <blockquote className="blockquote" key={_uniqueId("bq")}>
            {value.content}
            <cite>{value.name}</cite>
          </blockquote>
        )
      }
    }
  }
  return cards
}

const Testimonial = ({ head, data }) => {
  return (
    <>
      <section id="testimonials" className="testimonials">
        <div className="container">
          <div
            className="section-title"
            dangerouslySetInnerHTML={{ __html: head }}
          ></div>

          <div className="testimonials-slider swiper-container">
            <div className="swiper-wrapper" style={{ width: "100%" }}>
              <div className="swiper-slide" style={{ width: "100%" }}>
                <Slider {...settings}>{blockquoteRender(data)}</Slider>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Testimonial
