import React from "react"

const WhyContact = ({ head, cardOne, cardTwo, cardThree }) => {
  return (
    <>
      <section id="why-us" className="why-us section-bg">
        <div className="container">
          <div
            className="section-title"
            dangerouslySetInnerHTML={{ __html: head }}
          ></div>

          <div className="row">
            <div className="col-lg-4">
              <div className="box">
                <span>01</span>
                <div dangerouslySetInnerHTML={{ __html: cardOne }}></div>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="box">
                <span>02</span>
                <div dangerouslySetInnerHTML={{ __html: cardTwo }}></div>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="box">
                <span>03</span>
                <div dangerouslySetInnerHTML={{ __html: cardThree }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhyContact
