import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Config from '../config'

import Container from "../components/container"
import Header from "../components/header"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import WhyContact from "../components/whyContact"
import Testimonial from "../components/testimonial"
import Footer from "../components/footer"
import AboutUs from "../components/aboutUs"
import LastBlog from "../components/lastBlog"

const IndexPage = () => {
  const [home, setHome] = useState({})

  const { directusHome: node } = useStaticQuery(
    graphql`
      query {
        directusHome {
          og_title
          og_desc
          meta_title
          meta_keywords
          meta_desc
          og_image {
            data {
              full_url
            }
          }
        }
      }
    `
  )

  const getData = async () => {
    const call = await fetch(
      `${Config.apiUrl}/items/home?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setHome(response.data[0])
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {
        <SEO
          title={node.meta_title}
          desc={node.meta_desc}
          keywords={node.meta_keywords}
          ogTitle={node.og_title}
          ogDesc={node.og_desc}
          ogImage={node.og_image?.data.full_url}
        />
      }

      <Container>
        <Navbar />

        <Header
          image={home.banner?.data.full_url}
          title={home.title}
          subtitle={home.subtitle}
          cta={home.cta_text}
          ctaLink={home.cta_link}
        />

        <main id="main">
          {home.why_show && (
            <WhyContact
              head={home.why_content}
              cardOne={home.why_card_one}
              cardTwo={home.why_card_two}
              cardThree={home.why_card_three}
            />
          )}

          {home.about_show && (
            <AboutUs
              head={home.about_head}
              content={home.about_content}
              image={home.about_image?.data.full_url}
            />
          )}

          {home.blog_show && <LastBlog head={home.blog_content} />}

          {home.testimonial_show && (
            <Testimonial
              head={home.testtimonial_head}
              data={home.testimonial}
            />
          )}
        </main>

        <Footer />
      </Container>
    </>
  )
}

export default IndexPage
