import React, {useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import Config from '../config'

const TEMPLATE = "blogPageTemplate"

const LastBlog = ({ head }) => {
  const [posts, setPosts] = useState([])

  const getPosts = async () => {
    const call = await fetch(
      `${Config.apiUrl}/items/blogs?access_token=${Config.token}&fields=*.*.*&filter[status]=published&limit=3&sort=-sort`
    )
    const response = await call.json()
    setPosts(response.data)
  }

  const goTo = slug => {
    navigate(`/blog/${slug}`)
  }

  useEffect(() => {
    getPosts()
  }, [])

  const truncateContent = (content, index) => {
    if (content.length > index) {
      return `${content.slice(0, index)}...`
    }
    return content
  }

  return (
    <>
      <section className="why-us section-bg">
        <div className="container">
          <div
            className="section-title"
            dangerouslySetInnerHTML={{ __html: head }}
          ></div>

          <div className="row">
            {posts && posts.length &&
              posts.map((item, i) => (
                <div className="col-lg-4" key={i}>
                  <div onClick={() => goTo(item.slug)} onKeyDown={() => goTo(item.slug)} style={{cursor: 'pointer'}} role="button" tabIndex={0}>
                    <div className="about-img">
                      <img
                        src={item.banner?.data.full_url}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div
                      className="blog-box"
                      style={{ padding: "20px 30px 50px 30px" }}
                    >
                      <div>
                        <h5>{item.title}</h5>
                        {item.template && item.template === TEMPLATE ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: truncateContent(item.content, 150),
                            }}
                          ></div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: truncateContent(
                                item.paragraphe[0].content,
                                150
                              ),
                            }}
                          ></div>
                        )}
                      </div>
                      <div style={{ marginTop: "16px" }}>
                        <Link
                          to={`/blog/${item.slug}`}
                          style={{ float: "right" }}
                        >
                          Lire la suite
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default LastBlog
